import React, { useEffect, useState } from "react";
import app from "./base.js";

import { fetchUser } from "./Database.js";
import encodeFirebaseKey from "./Util.js";
import ByBackdrop from "./component/ByBackdrop.js";

import {  onChildAdded, onChildChanged, onChildRemoved } from "firebase/database";

import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import SaveUserDetail from "./SaveUserDetail.js";

export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [pending, setPending] = useState(true);
  const [courses, setCourses] = useState({});
  
  const getCourses = () => {
    return Object.values(courses);
  }
  const updateCourse = (course) => {
    setCourses([...courses, course]);
  }
 

  const signOut = () => {
    app.auth().signOut();
  };

  const [open, setOpen] = React.useState(pending);
  

  const authContextValue = { currentUser,signOut, setPending, courses, updateCourse, getCourses };

  useEffect(() =>{
    
    const courseRef = app.database().ref('courses');
    setCourses({});

    courseRef.on("child_added", (snapshot) => {

      const newCourse = snapshot.val();
      newCourse.key = snapshot.key;
      
      setCourses(prevState => {
        return {
          ...prevState,
          [snapshot.key]: newCourse
        }
      });
    });
  
    return () => {
      courseRef.off();
    };
  },[]);

  // Add a state to track whether to show the email verification dialog
const [showEmailVerificationDialog, setShowEmailVerificationDialog] = useState(false);

useEffect(() => {
  app.auth().onAuthStateChanged((user) => {
    console.log("SUHEL HERE"+JSON.stringify(user))
    setPending(true);
    if(user) {
      if(user.emailVerified) {
        const userRef = app.database().ref(`/users/${user.email.replaceAll('.', '_')}`);
        userRef.once('value', (snapshot) => {
          if (snapshot.exists()) {
            const key = snapshot.key;
            const databaseUser = snapshot.val();
            
            user.key = key;

            user.isAdmin = snapshot.val().isAdmin;
            user.createdAt = snapshot.val().createdAt;
            user.name = snapshot.val().name;
            user.phone = snapshot.val().phone;
            user.photoUrl = snapshot.val().photoUrl;
            user.address = snapshot.val().address;
            
            setCurrentUser(user);
            setPending(false);

            if(!user.name || !user.phone || !user.photoUrl) {
              handleOpenDialog();
            }

          } else {
            console.log("INSIDE SAVEING DETAIL OPF USER AFTER LOGIN : "+JSON.stringify(user));
            setCurrentUser(user);
            setPending(false);
            handleOpenDialog();
           
          }
        });  
      } else {
        // Show the email verification dialog
       
        setCurrentUser(null);
        setPending(false);
        setShowEmailVerificationDialog(true);
      }
    } else {
      setCurrentUser(null);
      setPending(false);
    }
  });
}, []);


  // Define a function to handle the dialog close
const handleEmailVerificationDialogClose = () => {
  app.auth().signOut();
  setShowEmailVerificationDialog(false);
};

const handleResentLink = () => {
  app.auth().currentUser.sendEmailVerification();
  handleEmailVerificationDialogClose();
};

const [dialogOpen, setDialogOpen] = useState(false);
 const handleOpenDialog = () => {
  setDialogOpen(true);
};

const handleCloseDialog = () => {
  setDialogOpen(false);
};


  if(pending){
    return <><ByBackdrop open = {pending}/></>
  }

  return (
    <>

    
    <Dialog open={showEmailVerificationDialog} onClose={handleEmailVerificationDialogClose}>
  <DialogTitle>Email Verification Required</DialogTitle>
  <DialogContent>
    <p>Please verify your email by clicking the link we sent you. If you didn't receive the email, you can request a new one by clicking the button below.</p>
  </DialogContent>
  <DialogActions>
    <Button onClick={handleEmailVerificationDialogClose}>OK</Button>
    <Button onClick={() => handleResentLink()}>Resend Email</Button>
  </DialogActions>
</Dialog>

<SaveUserDetail open={dialogOpen} onClose={handleCloseDialog}  user = {currentUser}/>
    <AuthContext.Provider
      value={
        authContextValue
      }
    >
      {children}
    </AuthContext.Provider>
    </>
    
  );
};
