import React, { useContext, useEffect , useState} from "react";
import app from "./base";
import { AuthContext } from "./Auth.js";
import Admin from "./Admin";
import ShowUserList from "./ShowUserList";
import { AppBar, Toolbar, Typography, IconButton } from '@mui/material';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { makeStyles } from '@mui/styles';
import Header from "./component/Header";
import { useHistory } from 'react-router-dom';
import FetchUserDetails from "./FetchUserDetails";
import CustomButton from "./component/CustomButton";
import SaveUserDetail from './SaveUserDetail';
const useStyles = makeStyles((theme) => ({
  
}));


const Home = () => {
  const history = useHistory();
  const { currentUser, signOut } = useContext(AuthContext);
  const classes = useStyles();

 useEffect(() => {

  if(!currentUser.isAdmin) {
      history.push(`/userInfo?userId=${currentUser.email}`);
  }
 },  currentUser)

 const [dialogOpen, setDialogOpen] = useState(false);
 const handleOpenDialog = () => {
  setDialogOpen(true);
};

const handleCloseDialog = () => {
  setDialogOpen(false);
};
  return (
    <>
    
      
      {currentUser.isAdmin && <FetchUserDetails />}
      {/* {currentUser.isAdmin && <CustomButton text="Add New User" variant="primary" onClick = {() => handleOpenDialog()} />} */}
      {/* <SaveUserDetail open={dialogOpen} onClose={handleCloseDialog} /> */}
      {!currentUser.isAdmin && <ShowUserList users={[currentUser]} />}
    </>
  );
};

export default Home;
