export const encodeFirebaseKey = (key) => {
    return key.replace(/\./g, ',');
};
export const formatDate = (dateString) => {
	const date = new Date(dateString);
	const day = date.getDate();
	const month = date.toLocaleString("default", { month: "long" });
	const year = date.getFullYear();
 
	// Add ordinal suffix to day
	let suffix;
	if (day >= 11 && day <= 13) {
	  suffix = "th";
	} else if (day % 10 === 1) {
	  suffix = "st";
	} else if (day % 10 === 2) {
	  suffix = "nd";
	} else if (day % 10 === 3) {
	  suffix = "rd";
	} else {
	  suffix = "th";
	}
  
	return `${day}${suffix} ${month} ${year}`;
  };
