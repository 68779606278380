import React, {useContext, useState} from "react";
import app from "./base";
import { AuthContext } from "./Auth.js";
import ShowUserList from "./ShowUserList";
import ByBackdrop from "./component/ByBackdrop";
import { useTheme } from '@mui/material/styles';
import { Box , useMediaQuery} from "@mui/material";
import CustomButton from "./component/CustomButton";
import CustomInputField from "./component/CustomInputField";
import SearchIcon from '@mui/icons-material/PersonSearch';

const FetchUserDetails = () => {
  console.log('MyComponent re-rendered');
  const isMobile = useMediaQuery('(max-width:700px)');
  const theme = useTheme();
  const [userRef , setUserRef] = useState(null);
  const {currentUser, signOut} = useContext(AuthContext);
  const [users, setUsers] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const fetchUserDetails = (event) => {
        setError('');
        event.preventDefault();
        let usersRef =  app.database().ref('users').orderByChild('createdAt');
        // const searchText = event.target.elements.searchText.value;
        if(searchText) {
          let searchTextLower = searchText.toLowerCase().trim();
          console.log(searchTextLower);
          
          const query = app.database().ref('users').orderByChild('email').startAt(searchTextLower).endAt(searchTextLower+'\uf8ff');
          
          executeDataRef(query);
        } else {
           if(isMobile) {
              setError('Pleases Input email id of user');
           } else {
              executeDataRef(usersRef);
           }
          
        }
    
    console.log("Here I am");
  };

  const executeDataRef = (ref) => {
    setLoading(true);
    ref.once('value').then(snapshot => {
      const userList = [];
      snapshot.forEach(childSnapshot => {
        const key = childSnapshot.key;
        const user = childSnapshot.val();
        const certificateList = [];
        const certificates = user.certificates ? Object.values(user.certificates) : [];
        
        if(certificates) {
          certificates.forEach(certificate => {
            certificateList.push(certificate);
          })
        } 
        user.certificates = certificateList;
        user.key = key;
        console.log(JSON.stringify(user));
        userList.push(user);
      });
      
      if(userList.length == 0 ) { 
        setError("No User Found");
      }
      userList.reverse();
      setUsers(userList);
      console.log(userList);
    })
    .catch((error) => {
      console.log(error);
      setError(error);
    })
    .finally(() => {
      setLoading(false);
    });
  };
  


  const handleChange = (e) => {
    setSearchText(e.target.value);
  };

  return (
<Box>
		<ByBackdrop open= {isLoading}/>
		<form onSubmit={fetchUserDetails} style={{padding:'20px'}}>
      {/* <ModernText text="Fetch Users" variant='HEADER'/> */}
			
				
        	
      <CustomInputField name="email" placeholder="Email Address to Search"
        icon={<SearchIcon/>}
        position='end'
        value={searchText}
        onChange={handleChange}
         />
				{/* // <TextField
        //     name="email"
        //     label="Email Address"
        //     fullWidth
        //     size='small'
        //     margin="normal"
        //     variant="outlined"
        //     sx={{ backgroundColor: 'white',
           
        //    }}
        //   /> */}
        <div style={{ display: 'flex', alignItems: 'center' }}>
              <p style={{ color: 'gray', fontSize:"12px" }}>{isMobile 
              ? '* Note: Enter Full Email Id or the Starting of Email Id' 
              : '* Note: Leave It Blank For All Users, Enter Full Email Id Or Starting Of Email Id'}</p>
            </div>
          {error && (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <p style={{ color: 'red', fontSize:"12px" }}>* {error}</p>
            </div>
          )}
          
			<CustomButton text="Fetch User" variant="primary"/>
      
		</form>
	
	<ShowUserList users = {users}/>
    </Box>
  );
};

export default FetchUserDetails;
