import React, { useState, useContext } from 'react';
import {
  Box,
  Collapse,
  Grid,
  IconButton,
  Paper,
  Typography,
  TablePagination
} from '@mui/material';
import {
AuthContext
} from '../Auth';
import {
KeyboardArrowDown,
KeyboardArrowUp
} from '@mui/icons-material';
import CertificateShowCase from './CertificateShowCase';
import {
formatDate
} from '../Util';
import ModernText from './ModernText';
import UserCardMobile from './UserCardMobile';


const ShowUserListMobile = ({
    users
    }) => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    
    const handleChangePage = (event, newPage) => {
    setPage(newPage);
    };
    
    const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    };
    
    const displayUsers = users
    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    .map((user) => (
    <UserCardMobile users={user} />
    ));
    
    return (
    <>
    {users.length > 0 && (
    <div>
    <Grid container spacing={2}>
    <UserCardMobile users={users} />
    </Grid>
    {users.length > rowsPerPage && <TablePagination
    rowsPerPageOptions={[5, 10, 25]}
    component="div"
    count={users.length}
    rowsPerPage={rowsPerPage}
    page={page}
    onPageChange={handleChangePage}
    onRowsPerPageChange={handleChangeRowsPerPage}
    />}
    </div>
    )}
    </>
    );
    };
    
    export default ShowUserListMobile;