import React, { useCallback, useContext, useEffect, useState } from "react";
import app from "./base.js";
import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Unstable_Grid2';
import ByBackdrop from "./component/ByBackdrop.js";
import Avatar from '@mui/material/Avatar'
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { AuthContext } from "./Auth.js";
import { Hidden , Box} from "@mui/material";
import { PRIMARY_TEXT_COLOR, PRIMARY_TITLE_COLOR, SECONDARY_TEXT_COLOR } from "./assets/theme/base/colors.js";
import ModernText from "./component/ModernText.js";
const cardColors = [
  '#2C3E50',
  '#2E4053',
  '#2F3E46',
  '#34495E',
  '#34495D',
  '#4E5D6C',
  '#515A5A',
  '#566573',
  '#6C7A89',
  '#7F8C8D',
  '#839192',
  '#95A5A6',
  '#A4B0BD',
  '#B4BCC3',
  '#BDC3C7',
  '#4B4E4D',
  '#212F3D',
  '#273746',
  '#7B8A8B'
  
];



const useStyles = makeStyles((theme) => ({
  main: {
    maxWidth:'1000px',
    paddingTop:50,
    marginBottom:50
  },
  title: {
    paddingTop:10,
    paddingBottom:10,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  courseDesc: {
    paddingLeft: 20,
    paddingRight: 20,
    width: "100%",
  },
  // card: {
  //   padding:20,
  //   width:'100%',
  //   display:'flex',
  //   justifyContent:'center',
  //   boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.25)",
  //   "&:hover": {
  //     boxShadow: "0px 10px 10px rgba(0, 0, 0, 0.25)",
  //     transform: "rotate(2deg) scale(1.25)",
  //   },
  // },
  card: {
    padding: 20,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.25)',
    '&:hover': {
      animation: '$wiggle 0.1s ease-in-out forwards',
    },
  },
  '@keyframes wiggle': {
    '0%': {
      transform: 'rotate(0deg)',
    },
    '50%': {
      transform: 'rotate(5deg)',
    },
    '100%': {
      transform: 'rotate(0deg)',
    },
  },
}));

const CourseShowCase = () => {
  const classes = useStyles();
  const [isLoading, setLoading] = useState(false);
  const minSize = 100;
  const {courses} = useContext(AuthContext);
 
  
  return (
    <div className={classes.main}>
       { Object.keys(courses).length > 0 &&
          <ModernText text="Experience the future of learning with our courses" variant='TITLE'/>
        }
    <Box mt={6}>
      <Grid container spacing={{ xs: 1, sm:2, md: 3, lg:7, xl: 10}}>
        <ByBackdrop open={isLoading} />
        {Object.keys(courses).length > 0 && Object.keys(courses).map((key, index) => (
          <Grid item xs={12} sm={12}  style={{ display: 'flex', alignItems:'center' ,flexDirection: index % 2 === 0 ?  'row' : 'row-reverse' }}>
            <div className={classes.courseBlock} style={{ width: "100%" }}>
            <Card className={classes.card} style={{ backgroundColor: cardColors[index % cardColors.length] , display:'flex', flexDirection:'column', paddingLeft:0, paddingRight:0 }}>
                <Typography variant="h6" component="h3" style={{  fontSize: "30px", color:"#fff" , userSelect: 'none'}}>
                  <b>{courses[key].courseName}</b>
                </Typography>
                <Hidden mdUp>
                  <div className={classes.courseDesc} style={{ width: "100%" }}>
                      <Typography variant="body2" color="#fff" component="p" style={{ userSelect: 'none'}}>
                        {courses[key].description}
                      </Typography>
                  </div>
                </Hidden>
              </Card>
            </div>
            <Hidden mdDown>
            <div className={classes.courseDesc} style={{ width: "100%" }}>
              <Typography variant="body2" color={SECONDARY_TEXT_COLOR} component="p"  style={{ userSelect: 'none' }}>
                {courses[key].description}
              </Typography>
            </div>
            </Hidden>
          </Grid>
        ))}
      </Grid>
      </Box>
    </div>
  );
};

export default CourseShowCase;
