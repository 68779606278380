import React from 'react';
import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

const useStyles = makeStyles((theme) => ({
  footer: {
    backgroundColor: '#212529',
    color: '#fff',
    padding: 20,
  },
  link: {
    color: '#fff',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));

const Footer = () => {
  const classes = useStyles();

  return (
    <footer className={classes.footer}>
        
      <Grid container spacing={2} justifyContent="space-evenly">
        {/* <Grid item xs={12} sm={6} md={3}>
          <Typography variant="h6" gutterBottom>
            About Us
          </Typography>
          <Typography variant="body2" component="p">
            InfoClick Technologies is a leading IT services provider, serving clients across the globe.
          </Typography>
        </Grid> */}
        <Grid item xs={12} sm={6} md={3}>
          <Typography variant="h6" gutterBottom>
            Contact Us
          </Typography>
          <Typography variant="body2" component="p">
            <a href="tel:+918299389038" className={classes.link}>
                +91 8299389038
            </a>,
            <a href="tel:+919984474540" className={classes.link}>
                +91 9984474540
            </a>
          </Typography>
          <Typography variant="body2" component="p">
            <a href="mailto:infoclicktechnologies@gmail.com" className={classes.link}>
            infoclicktechnologies@gmail.com
            </a>
          </Typography>
         
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Typography variant="h6" gutterBottom>
            Address
          </Typography>
         
          <Typography variant="body2" component="p">
          Near Anta Chauraha
Opposite Water Tank,
Shahjahanpur, Uttar Pradesh
242001
          </Typography>
         
        </Grid>
      </Grid>
      <Typography variant="body2" align="center" gutterBottom>
        &copy; 2022 InfoClick Technologies. All rights reserved.
      </Typography>
     
    </footer>
  );
};

export default Footer;
