import React, { useState , useContext, useEffect} from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Divider, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import app from '../base';
import { AuthContext } from '../Auth';
import ModernText from './ModernText.js';
import AddCertificateModal from '../AddCertificate';
import DownloadIcon from '@mui/icons-material/Download';
import DeleteIcon from '@mui/icons-material/Delete';
import ViewFile from '@mui/icons-material/RemoveRedEye';
import { formatDate } from '../Util';
import { makeStyles } from '@mui/styles';
import ByBackdrop from './ByBackdrop';
import { PRIMARY_COLOR, DANGER, PRIMARY_TITLE_COLOR, SECONDARY_TEXT_COLOR } from "../assets/theme/base/colors.js";


const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop:'10px',
    marginBottom:'20px'
  },
  certificateItem: {
    display: "flex",
    justifyContent: "space-between",
    padding: 10,
    borderBottom: `1px solid ${SECONDARY_TEXT_COLOR}`,
    '&:last-child': {
      borderBottom: 'none',
    },
  },
  certificateDetails: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  certificateActions: {
    display: "flex",
    alignItems: "center",
    marginLeft: "auto",
    '& > button': {
      marginLeft: 10,
    },
  },
  certificateDetailsBox: {
    display: "flex",
    paddingTop:'8px',
    
  },
  certificateBox: {
    // border: '1px solid grey',
    // borderRadius: '10px',
    marginBottom: '10px',
    // transition: 'background-color 0.2s ease',
    // '&:hover': {
    //   backgroundColor: 'lightblue', // Light blue background color
    // },
  }
  
}));

const CertificateShowCase = ({user}) => {
  const { currentUser, courses } = useContext(AuthContext);
  const history = useHistory();
  const [pending, setPending] = useState(true);
  const [certificates, setCertificates] = useState([]);
  const classes = useStyles();
  const handleDownloadPdf = (url) => {
    window.open(url, '_blank');
  }

  const viewPdfFile = (url) => {
    history.push(`/pdf-viewer?pdfUrl=${url}`);
  }

  const pushCertificate = (certificate) => {
    certificates.push(certificate);
  }
  
  
 
  useEffect(() => {
    if (user && user.email) {
      setPending(true);
      const refUrl = `userCertificates/${user.email.replaceAll('.', '_')}`;
      app.database().ref(refUrl).on('value', snapshot => {
        const certificateList = snapshot.val();
        if (certificateList) {
          const certificateIds = Object.values(certificateList);
          const certificatePromises = certificateIds.map(certificateId =>
            app.database().ref(`certificates/${certificateId}`).once('value')
          );
          Promise.all(certificatePromises)
            .then(certificateSnapshots => {
              const certificates = certificateSnapshots
                .map(snapshot => snapshot.val())
                .filter(certificate => certificate !== null); // Filter out null certificates
              // Sort the certificates based on the uploadDate property in descending order
              certificates.sort((a, b) => b.uploadDate - a.uploadDate);
              setCertificates(certificates);
              setPending(false);
            })
            .catch(error => {
              console.error('Error getting certificate details: ', error);
              setPending(false);
            });
        } else {
          setCertificates([]);
          setPending(false);
        }
      });
    }
  }, [user]);
  
  

  

  const deleteCertificate2 = async (certificateId) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this certificate?");
    if (confirmDelete) {
      try {
        // Remove the certificate details from the database
        const certificateRef = app.database().ref(`/certificates/${certificateId}`);
        await certificateRef.remove();
  
        // Delete the certificate file from storage
        const certificateFileRef = app.storage().ref().child(`certificates/${user.email.replaceAll('.','_')}/${certificateId}.pdf`);
        await certificateFileRef.delete();
  
        // Remove the certificate ID from the user's certificates list
        const userCertificateRef = app.database().ref(`userCertificates/${user.email.replaceAll('.', '_')}/${certificateId}`);
        await userCertificateRef.remove();
  
        console.log("Certificate deleted successfully");
      } catch (error) {
        console.error("Error deleting certificate: ", error);
      }
    }
  };

  
  const deleteCertificate = (certificateId) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this certificate?");
    if (confirmDelete) {
      // Get the certificate details from the database
      const certificateRef = app.database().ref(`/certificates/${certificateId}`);
      certificateRef.once("value")
        .then((snapshot) => {
          const certificate = snapshot.val();
          if (certificate) {
            // Remove the certificate details from the database
            certificateRef.remove()
              .then(() => {
                console.log("Certificate details deleted successfully");
                // Delete the certificate file from storage
                const certificateFileRef = app.storage().ref().child('certificates/'+user.key+'certificateId'+'.pdf');
                certificateFileRef.delete()
                  .then(() => {
                    console.log("Certificate file deleted successfully");
                  })
                  .catch((error) => {
                    console.error("Error deleting certificate file: ", error);
                  });
                const userCertificateRef = app.database().ref('users/'+user.email.replaceAll('.','_')+'/certificates');
                userCertificateRef.remove()
                .then(() => {
                  console.log("Certificate deleted successfully");
                })
                .catch((error) => {
                  console.error("Error deleting certificate: ", error);
                });  
              })
              .catch((error) => {
                console.error("Error deleting certificate details: ", error);
              });
          }
        })
        .catch((error) => {
          console.error("Error getting certificate details: ", error);
        });
    }
  };
  
  
  
  return (
    
    <div style={{ padding: '1rem' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between' , alignItems:'center', marginBottom:'20px' }}>
        <div style={{ display: 'flex', flexDirection: 'row'}}>
          <ModernText variant='HEADER' text="Certificates" />
          {/* <ModernText  variant='SECONDARY'  text={certificates ? certificates.length : '0'} /> */}
        </div>
        <div>
          <AddCertificateModal user={user} />
        </div>
      </div>
  
      <div >
        {
          pending ? ( // Show loading state if `loading` is true
          <div style={{ position: 'relative' }}>
       
          <div style={{ display:'flex' }}>
            <Typography variant="h6">Loading...</Typography>
          </div>
        </div>
        ) : (
         certificates.filter(certificate => certificate != null).map((certificate) => {
          // const course = courses[certificate.courseId];
          return (
            <div key={certificate.certificateId} className={classes.certificateBox} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '1rem' }}>
              <Divider/>
              <div style={{ flexGrow: 1 }} >
                {/* <ModernText variant="TITLE" text={course ? course.courseName : 'N/A'}/> */}
                <ModernText variant="TITLE" text={certificate.courseName} fontWeight='500' />
                <div className={classes.certificateDetailsBox}>
                <ModernText variant="SECONDARY" text="Certificate Id:  &nbsp; " />
                <ModernText variant="SECONDARY" fontWeight='700'
                text={`${certificate.certificateId}`}/>
                </div>

                { certificate.issueDate &&  
                 <div className={classes.certificateDetailsBox}>
                  <ModernText variant="SECONDARY" text="Issue Date: &nbsp; " />
                  <ModernText variant="SECONDARY" fontWeight='700'
                text={`${formatDate(certificate.issueDate)}`}/> </div>
                }

                { certificate.fromDate && 
                 <div className={classes.certificateDetailsBox}>
                  <ModernText variant="SECONDARY" text="Duration: &nbsp;" />
                  <ModernText variant="SECONDARY" fontWeight='700'
                text={`${formatDate(certificate.fromDate)} - ${formatDate(certificate.toDate)}`}/> </div>
                }
                
              </div>
  
              <div style={{ display: 'flex' }}>
                <IconButton
                  aria-label="download"
                  style={{ color: PRIMARY_COLOR }}
                  onClick={() => handleDownloadPdf(certificate.downloadURL)}
                >
                  <DownloadIcon />
                </IconButton>
                { currentUser.isAdmin && <IconButton
                  aria-label="Delete"
                  style={{ color: DANGER }}
                  onClick={() => deleteCertificate2(certificate.certificateId)}
                >
                  <DeleteIcon />
                </IconButton>}
              </div>
            </div>
          );
        }))}
      </div>
    </div>
  );
  
};

export default CertificateShowCase;
