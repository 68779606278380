import React from "react";
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";
import Home from "./Home";
import Login from "./Login";
import { AuthProvider } from "./Auth";
import PrivateRoute from "./PrivateRoute";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import "bootstrap/dist/css/bootstrap.min.css";
import Footer from "./Footer";
import PdfViewerD from "./PdfViewer";
import Header from "./component/Header";
import UserInfo from "./UserInfo";
import { BACKGROUND_COLOR, PRIMARY_TITLE_COLOR, SECONDARY_TEXT_COLOR } from "./assets/theme/base/colors.js";

const useStyles = makeStyles(() => ({
  main: {
    fontFamily: "Montserrat, sans-serif",
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    backgroundColor: BACKGROUND_COLOR
  },
  content: {
    width:'100%',
    maxWidth: '1000px',
    margin: '0 auto',
    marginTop: '10px',
    marginBottom: '20px',
    minHeight: '50vh',  // Set the minimum height to be the screen height
  },
  header: {
    width: '100%',
    padding:'20px'
  },
  footer: {
    width: "100%",
  },
}));

const App = () => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <AuthProvider>
      <Router>
        <Box className={classes.main}>
          <Header className={classes.header}/>
          <Box className={classes.content}>
            <PrivateRoute exact path="/" component={Home} />
            <Route exact path="/login" component={Login} />
            <PrivateRoute path="/pdf-viewer" component={PdfViewerD} />
            <PrivateRoute path="/userInfo" component={UserInfo} />
            {/* <Route exact path="/signup" component={SignUp} /> */}
            {/* <Route path="/*">
              <Redirect to="/" />
            </Route> */}
          </Box>
          <Box className={classes.footer}>
          <Footer/>
          </Box>
        </Box>
      </Router>
    </AuthProvider>
  );
};

export default App;
