import React, { useState } from "react";
import { TextField, InputAdornment , useMediaQuery} from "@mui/material";
import { ACCENT_COLOR, DARK_PRIMARY_COLOR, INPUT_BACKGROUND_COLOR, INPUT_TEXT_COLOR, PRIMARY_BUTTON_BACKGROUND_COLOR } from "../assets/theme/base/colors";

const CustomInputField = ({ value = '', onChange, name, required = false, type = 'text', placeholder = '', multiline = false, icon=null, position = 'end', onClick ={}, disabled=false, maxLength = 50 }) => {
  const [iconColor, setIconColor] = useState(DARK_PRIMARY_COLOR);
  const adornmentPosition = (position === 'start') ? 'start' : 'end';

  const handleIconClick = () => {
    // onClick(); // call the onClick function passed as prop
    // Perform other action on icon click if needed
  }
  const isMobile = useMediaQuery('(max-width:700px)');

  return (
    <TextField
      name={name}
      value={value}
      onChange={onChange}
      fullWidth
      multiline={multiline ? true : false}
      rows={3}
      type={type}
      size={multiline ? 'medium' : 'small'}
      margin="normal"
      variant="outlined"
      autoComplete='off'
      placeholder={placeholder}
      required={required}
      disabled={disabled}
      InputProps={{
        style: {
          fontSize: isMobile ? '13px' : '15px',
          color: 'black',
          fontWeight: 300,
          letterSpacing: '0.3px',
          borderRadius: '5px',
          backgroundColor: INPUT_BACKGROUND_COLOR,
          padding: 2,
          marginTop: -10,
          marginBottom: 2
        },
        [adornmentPosition + 'Adornment']: (
          icon && (
            <InputAdornment position={adornmentPosition} onClick={handleIconClick}>
              {React.cloneElement(icon, {
                sx: {
                  fontSize: '23px',
                  color: iconColor,
                  marginRight: '5px',
                  marginLeft: '5px',
                  cursor: 'pointer'
                }
              })}
            </InputAdornment>
          )
        )
      }}
      InputLabelProps={{
        style: {
          color: INPUT_TEXT_COLOR,
          fontWeight: 600,
          fontSize: '14px'
        }
      }}
      maxLength= {maxLength}
    />
  );
}

export default CustomInputField;
