import React, { useCallback, useContext, useState } from "react";
import { withRouter, Redirect } from "react-router";
import { AuthContext } from "./Auth.js";
import { makeStyles } from '@mui/styles';
import CourseShowCase from "./CourseShowCase.js";
import { useMediaQuery, Hidden, Box, Chip } from '@mui/material';
import Lottie from 'react-lottie';
import developerCodingAnimation from './assets/lottie/developer-coding.json';
import ModernText from "./component/ModernText.js";
import CustomButton from "./component/CustomButton.js";
import app from "./base.js";
import firebase from "firebase/compat/app";
import { CheckCircleOutline } from '@mui/icons-material';
import './css/GoogleButton.css';
import { Button , Divider, Paper} from "@mui/material";
import { formatDate } from './Util';
import GoogleButton from 'react-google-button'
import CustomInputField from "./component/CustomInputField.js";
import ByBackdrop from "./component/ByBackdrop.js";
import { PRIMARY_COLOR } from "./assets/theme/base/colors.js";

const useStyles = makeStyles((theme) => ({
  main: {
    "@media (min-width: 600px) and (max-width: 700px)": {
      paddingLeft:20,
      paddingRight:20
    },

    "@media (min-width: 700px)  and (max-width: 1000px)": {
      paddingLeft:70,
      paddingRight:70
    },

    "@media (min-width: 1000px)": {
      paddingLeft:120,
      paddingRight:120
    },
  },
  formSection: {
    display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginTop:'20px'
  },
  sideImageContainer: {
    flex: 1,
    // display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: 'auto',
    maxWidth: '400px',
    // paddingRight: 10, // applies to all screen sizes
    "@media (max-width: 900px)": {
      display:'none'
    },
    // "@media (min-width: 960px)": {
    //   paddingRight: 10,
    // },
  },
  formContainer: {
    flex:1,
    alignItems: 'center',
    justifyContent: 'center',
    width:'100%',
    margin:10,
    padding:20,
    // "@media (min-width: 600px)": {
    //   borderRadius: 10,
    //   padding: 40,
    //   border: '1px solid #ccc',
    //   margin:40,
    // },
    
  },
  validateCertificate: {
    marginTop:'20px',
  },
  divider: {
    marginTop:'20px',
    marginBottom: '20px'
  },
  verifiedCertificateBox: {
    paddingTop:'10px',
    display:'flex',
    flexDirection:'row',
    justifyContent:'space-between'
  },
  verifyBox: {
    display: 'flex',
    flexDirection: 'column',

  }
}));

const initialFormData = {
  certificateId: ''
  
};

const Login = ({ history }) => {
  const isMobile = useMediaQuery('(max-width:700px)');
  const provider = new firebase.auth.GoogleAuthProvider();
  const classes = useStyles();
  const [formData, setFormData]= useState(initialFormData);
  const { currentUser } = useContext(AuthContext);
  const [isRegistered, setRegistered] = useState(true);
  const [certifcateDetail, setCertificateDetail] = useState(null);
  const [certificateError, setCertificateError] = useState(null);
  const [isLoading, setLoading] = useState(false); 
  const handleSignIn = () => {
    app.auth().signInWithPopup(provider)
      .then((result) => {
        // The signed-in user info.
        const user = result.user;
      
        console.log(JSON.stringify(user));
      })
      .catch((error) => {
        // Handle error here
        console.log(error);
      });
  };

  const handleForm = (value) => {
    console.log(value);
    setRegistered(value);
  }
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: developerCodingAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };
  if (currentUser) {
    return <Redirect to="/" />;
  }

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

 
  const verifyCertificate = () => {
    if(!formData.certificateId) {
      setCertificateError('*Please Enter Certificate Id');
      return;
    }
    setCertificateError(null);
    setCertificateDetail(null)
    setLoading(true);
    console.log(formData.certificateId);
    app.database().ref('certificates/'+formData.certificateId).once('value').then(snapshot => {
      if(snapshot && snapshot.exists()) {
        setCertificateDetail(snapshot.val());
      } else {
        setCertificateError("* No Certificate Found, Please verify Certificate Id");
      }
    }).catch(error =>{
      setCertificateError("* No Certificate Found, Please verify Certificate Id");
    }).finally (() => {
      setLoading(false);
    })
    
    
  }
  
  return (<>
  
  <ByBackdrop open={isLoading} />
   <div className={classes.main}>
   <div>
              <center>
                <ModernText text="Institute Of Information Technologies" variant='HEADER'/>
                <ModernText text="A Unit Of InfoClick Pvt. Ltd." variant='TITLE'/>
              </center>
            </div>
    <Paper className={classes.formSection} elevation={isMobile ? 0 : 2}>
      <div className={classes.sideImageContainer}>
        <Lottie options={defaultOptions} />
      </div>
      <div className={classes.formContainer}>
        {/* { isRegistered && <LoginBox  changeStatus={handleForm}/> } */}
        {/* { !isRegistered && <SignUpBox  changeStatus={handleForm}/> } */}

        <GoogleButton  
        style={{width:'100%', backgroundColor: PRIMARY_COLOR, color:'white', marginBottom:'20px'}}
        type="light"
        onClick={() =>handleSignIn()}/>
        <Divider className={classes.divider}>OR</Divider>
        <div className={classes.validateCertificate} >
          <CustomInputField 
          name="certificateId"
          id="certificateId"
          value={formData.certificateId}
          onChange={handleChange}
          placeholder="Certificate Id*"
          />
          <CustomButton text="Verfiy" onClick={() => verifyCertificate()}/>
          {certificateError && <Box>
            <ModernText text={certificateError} variant='ERROR'/>
          </Box>
          }
          {certifcateDetail && <Box className={classes.verifiedCertificateBox}>
            <Box className={classes.verifyBox}>
              <ModernText text={certifcateDetail.ownerName} variant='TITLE' fontWeight='500'/>
              <ModernText text={certifcateDetail.courseName} variant='TITLE' fontWeight='500'/>
              {certifcateDetail.issueDate && <ModernText text={`Issued On ${formatDate(certifcateDetail.issueDate)}`} variant='SECONDARY' fontWeight='700'/>}
              {certifcateDetail.fromDate && <ModernText text={`${formatDate(certifcateDetail.fromDate)} - ${formatDate(certifcateDetail.toDate)}`} variant='SECONDARY' fontWeight='500'/>}
              
            </Box>
            <Box className={classes.verifyBox}>
              
              <Chip label="VERIFIED" color="success" style={{fontSize: '11px'}} icon={<CheckCircleOutline/>} />
              {/* <ModernText text="VERIFIED" color='green' fontWeight='700'/> */}
            </Box>
          </Box>
          }
        </div>
      </div>
    </Paper>
    
      <center><CourseShowCase/></center>
    
    </div>
   
    </>
  );
};

export default withRouter(Login);
