import React, { useContext, useState } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Box,
  Typography,
  Stack,
  Drawer,
  List,
  ListItem,
  MenuList,
  Divider,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Avatar,
} from "@mui/material";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import Logo from "../assets/images/LogoNameBlue.png";
import app from "../base";
import { AuthContext } from "../Auth";
import AddCoursePopup from "../AddCoursePopUp";
import MenuIcon from "@mui/icons-material/Menu";
import AddCourseIcon from "@mui/icons-material/AutoStories";
import AddUserIcon from "@mui/icons-material/PersonAdd";
import ModernText from "./ModernText";
import SaveUserDetail from "../SaveUserDetail";
import { PRIMARY_COLOR, DANGER, PRIMARY_TITLE_COLOR, SECONDARY_TEXT_COLOR } from "../assets/theme/base/colors.js";

const Header = () => {
  const { currentUser } = useContext(AuthContext);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [addCourseOpen, setCourseOpen] = useState(false);
  const hash = Math.random().toString(36).substring(2, 8);

  const handleSignOut = async () => {
    handleCloseAbout();
    try {
      if (window.confirm("Are you sure you want to sign out?")) {
        await app.auth().signOut();
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const openAddCourse = async () => {
    handleCloseAbout();
    setCourseOpen(true);
  };

  const handleOpenDialog = () => {
    handleCloseAbout();
   setDialogOpen(true);
 };
 
 const handleCloseDialog = () => {
  handleCloseAbout();
   setDialogOpen(false);
 };


  const handleCloseAbout = () => setDrawerOpen(false);

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  return (
    <>
      <AppBar position="static" sx={{ bgcolor: "transparent", boxShadow: "none", padding:'10px' }}>
        <Toolbar sx={{ justifyContent: "space-between", alignItems: "center" }}>
          {currentUser && (
            <Box sx={{ display: "flex" }}>
              <IconButton
                sx={{ color: "black" }}
                onClick={handleDrawerToggle}
                edge="start"
                color="inherit"
                aria-label="menu"
              ><MenuIcon /></IconButton>
            </Box>
          )}
          <Box sx={{ display: "flex", alignItems: "center", flexDirection:'column',flexGrow: 1 }}>
            <Box>
              <img src={Logo} alt="Logo" height="64px" width="auto" />
            </Box>
          </Box>
        </Toolbar>
      </AppBar>

      <Drawer
          anchor="left"
          open={drawerOpen}
          onClose={handleCloseAbout}
          variant="temporary"
        >
        <Box sx={{
          padding:'20px',
          paddingTop:'40px',
          backgroundColor: PRIMARY_COLOR,
          height:'100%',
          maxWidth:'300px'
        }}>
          <Box  sx={{
            display:'flex',
            flexDirection:'column',
            alignItems:'center',
            padding:'20px'
        }}>
           <Avatar
              sx={{ 
                width: '100px',
                height: '100px',
                backgroundColor: 'orange',
                marginBottom: '20px',
                border: '4px solid white',
                borderColor: 'white'
              }}
              // src={`https://robohash.org/${currentUser ? currentUser.email : hash}?set=set4`} />
                src={currentUser ? currentUser.photoUrl : ''} />
            <ModernText text={currentUser  ? currentUser.name : 'Guest'} variant='TITLE' color='white'/>
          </Box>
          <Box>
              <MenuList>
                {currentUser && currentUser.isAdmin && 
                <MenuItem onClick={openAddCourse}>
                   <ListItemIcon>
                  <AddCourseIcon fontSize="small"  style={{ color: 'white' }}/>
                </ListItemIcon>
                <ListItemText> <ModernText  text='Add New Course' variant='TITLE' color='white'/></ListItemText>
                
               </MenuItem>}

               {currentUser && currentUser.isAdmin && 
                <MenuItem onClick={handleOpenDialog}>
                   <ListItemIcon>
                  <AddUserIcon fontSize="small" style={{ color: 'white' }}/>
                </ListItemIcon>
                <ListItemText> <ModernText  text='Add New User' variant='TITLE' color='white'/></ListItemText>
                
               </MenuItem>}

                <MenuItem onClick={handleSignOut}>
                  <ListItemIcon>
                  <ExitToAppIcon fontSize="small" style={{ color: 'white' }}/>
                  </ListItemIcon>
                  <ListItemText> <ModernText  text='Sign Out' variant='TITLE' color='white'/></ListItemText>
                </MenuItem>
                {/* <Divider /> */}
               
              </MenuList>
          </Box>
        </Box>
      </Drawer>
      <AddCoursePopup show={addCourseOpen} setShow={setCourseOpen}/>
      <SaveUserDetail open={dialogOpen} onClose={handleCloseDialog} user={currentUser}  />
    </>
  );
};

export default Header;

