import React, { useState , useContext, useCallback, useEffect} from "react";
import { Modal } from "react-bootstrap";
import {Button, ListItemText, Alert} from '@mui/material'
import app from './base'
import { AuthContext } from "./Auth";
import { makeStyles } from '@mui/styles';
import ByBackdrop from "./component/ByBackdrop";
import { Dialog, DialogTitle, IconButton, DialogContent } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CustomInputField from "./component/CustomInputField";
import CustomButton from "./component/CustomButton";
const useStyles = makeStyles((theme) => ({
  main: {
    margin:40
  },
  button: {
    marginTop: 5,
  },
  inputBox: {
    lineHeight:0.1,
    marginTop:20
  },
  label : {
    fontWeight: 700,
    color: "grey",
    fontSize: "13px"
  }
}));

const initialFormData = {
  courseName: '',
  duration: '',
  description: ''
};


const AddCoursePopup = ({show, setShow}) => {
  const classes = useStyles();
  
  
  const [isLoading, setLoading] = useState(false); 
  
  const [formData, setFormData] = useState(initialFormData);
  const [courseName, setCourseName] = useState('');
  const [duration, setDuration] = useState('');
  const [description, setDescription] = useState('');
  const [severity, setSeverity] = useState('');
  const [message, setMessage] = useState('');
  
  const handleChange = (e) => {
    setSeverity('');
    setMessage('');
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleClose = () =>  {
    setSeverity('')
    setShow(false) };
  const handleShow = () =>  {
    setSeverity('');
    setShow(true)};

  const handleSave = useCallback(

    async event => {
      event.preventDefault();
      setLoading(true);
      const newCourseRef = app.database().ref("courses").push();
      const newCourse = {
        courseName: formData.courseName,
        duration: formData.duration,
        description: formData.description,
      };
      newCourseRef.set(newCourse)
      .then(() => {
        setFormData(initialFormData);
        setSeverity("success");
        setMessage("Course Added Successfully");
        // setShow(false);
      })
      .catch((error) => {
        console.error(error);
        setSeverity("error");
        setMessage("Unable to Add Course, Please try again later");
      }) 
      .finally(() => {
        setLoading(false);
      });
    },
    [formData]
  );
  

  return (
    <>
    {/* <ListItemText onClick={handleShow}>Add Course</ListItemText> */}
     
    <Dialog open={show} onClose={handleClose}>
        <DialogTitle sx={{display:'flex', justifyContent:'space-between'}}>Add Course <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton></DialogTitle>
        
    <DialogContent style={{maxWidth:'400px'}}>
        <form onSubmit={handleSave}>
          <CustomInputField name="courseName" type="text" 
          value={formData.courseName} required="true" placeholder="Course Title"
          onChange={handleChange}/>
          <CustomInputField name="duration" type="number" 
          value={formData.duration} required="false" placeholder="Duration In Days"
          onChange={handleChange}/>
          <CustomInputField name="description" type="text" 
          value={formData.description} required="true" placeholder="Description" multiline='true'
          onChange={handleChange}/>
         
          <ByBackdrop open={isLoading} />
          
          {severity && 
          <Alert severity={severity}>{message}</Alert>
          }
          <CustomButton text="Add Course" variant="primary"/>
        </form>
       </DialogContent>
       </Dialog>
    </>
  );
}

export default AddCoursePopup;