import React, { useState, useContext } from 'react';
import {TableBody,TableCell,TableContainer,TableHead,TableRow,Paper,TablePagination,
} from '@mui/material';
import { Box, Collapse, IconButton, Table } from '@mui/material';
import { AuthContext } from './Auth';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import CertificateShowCase from './component/CertificateShowCase';
import { formatDate } from './Util';
import ModernText from './component/ModernText';

const ShowUserListWeb = ({ users }) => {
  const [openCollapse, setOpenCollapse] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  // Define the handleCollapse function
  const handleCollapse = (userId) => {
    setOpenCollapse(userId === openCollapse ? '' : userId);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const displayUsers = users
    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    .map((user, index) => (
      <React.Fragment key={user.key}>
        <TableRow hover>
        <TableCell sx={{ fontWeight: 'bold' }}>{page*rowsPerPage + index+1}</TableCell>
        <TableCell><img src={user.photoUrl} style={{ width: '50px',
    height: 'auto',
    borderRadius: '50%',
    marginRight:'5px'}}/></TableCell>
          <TableCell><ModernText text={user.name} variant='TITLE'/></TableCell>
          <TableCell><ModernText text={user.email} variant='TITLE'/></TableCell>
          <TableCell><ModernText text={user.phone} variant='TITLE'/></TableCell>
          <TableCell><ModernText text={formatDate(user.createdAt)} variant='TITLE'/></TableCell>
          <TableCell align="center">
            <IconButton onClick={() => handleCollapse(user.key)}>
              {openCollapse === user.key ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
            <Collapse in={openCollapse === user.key} timeout="auto" unmountOnExit>
              <CertificateShowCase user={user} />
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    ));

  return (
    <>
      {users.length > 0 && (
        <div style={{marginTop:'5vh'}}>
          <TableContainer component={Paper}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                <TableCell sx={{ fontWeight: 'bold', color: 'white', backgroundColor: '#333' }}>SNO</TableCell>
                <TableCell sx={{ fontWeight: 'bold', color: 'white', backgroundColor: '#333' }}>Photo</TableCell>
                  <TableCell sx={{ fontWeight: 'bold', color: 'white', backgroundColor: '#333' }}>Name</TableCell>
                  <TableCell sx={{ fontWeight: 'bold', color: 'white', backgroundColor: '#333' }}>Email</TableCell>
                  <TableCell sx={{ fontWeight: 'bold', color: 'white', backgroundColor: '#333' }}>Phone Number</TableCell>
                  <TableCell sx={{ fontWeight: 'bold', color: 'white', backgroundColor: '#333' }}>Created At</TableCell>
                  <TableCell sx={{ fontWeight: 'bold', color: 'white', backgroundColor: '#333' }}>Certificates</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{displayUsers}</TableBody>
            </Table>
          </TableContainer>
          {users.length > rowsPerPage && <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={users.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}/>}
            </div>)}
            </>);
      };
export default ShowUserListWeb;