import React, { useCallback, useContext, useEffect, useState } from "react";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import ModernText from "./ModernText";
export const ByBackdrop = (props) => {
    const {open} = props
    return (
        <>
        <Backdrop
    sx={{ color: '#fff', display:'flex',flexDirection:'column', zIndex: (theme) => theme.zIndex.drawer + 1 }}
    open={open}
  ><CircularProgress color="inherit" sx={{margin:'20px'}}/> 
<ModernText text='Loading...' variant='TITLE' color='white'/>
  </Backdrop>
        </>
    )
}

export default ByBackdrop;