import React, { useState} from 'react';
import { Box } from '@mui/material';
import { useLocation } from 'react-router-dom';

import ModernText from './component/ModernText';
// import { Viewer } from '@react-pdf-viewer/core';
// import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
// import '@react-pdf-viewer/core/lib/styles/index.css';
// import '@react-pdf-viewer/default-layout/lib/styles/index.css';

const PdfViewerD = () => {
    // const defaultLayoutPluginInstance = defaultLayoutPlugin();
    const [numPages, setNumPages] = useState(null);
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const pdfUrl = searchParams.get('pdfUrl');
  
    console.log(pdfUrl);
   
    return (
        <>
            <ModernText text="Hello"/>
            {/* <Viewer
    fileUrl={pdfUrl}
    plugins={[
        // Register plugins
        defaultLayoutPluginInstance,
        
    ]}
/> */}
        </>
    );
};

export default PdfViewerD;
