import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/database";
import "firebase/compat/storage";


const app = firebase.initializeApp({
  apiKey: "AIzaSyCIIHbHQqXHta88gWaRRi3f99elyjDv7i8",
  authDomain: "infoclick-technologies.firebaseapp.com",
  databaseURL: "https://infoclick-technologies-default-rtdb.firebaseio.com",
  projectId: "infoclick-technologies",
  storageBucket: "infoclick-technologies.appspot.com",
  messagingSenderId: "662425067472",
  appId: "1:662425067472:web:87bce9b098b27702e223d5",
  measurementId: "G-QPQJV25853"
});

export default app;
