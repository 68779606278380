import React, { useContext, useEffect, useState } from "react";
import app from "./base";
import { AuthContext } from "./Auth.js";
import Admin from "./Admin";
import ShowUserList from "./ShowUserList";
import { AppBar, Toolbar, Typography, IconButton, Paper, Avatar, Box, Table, TableCell, TableRow , useMediaQuery} from '@mui/material';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { makeStyles } from '@mui/styles';
import Header from "./component/Header";
import FetchUserDetails from "./FetchUserDetails";
import ModernText from "./component/ModernText";
import InfoText from './component/InfoText';
import { useLocation } from 'react-router-dom';
import ByBackdrop from "./component/ByBackdrop";
import DownloadIcon from '@mui/icons-material/Download';
import { ACCENT_COLOR, PRIMARY_COLOR, SECONDARY_BUTTON_BACKGROUND_COLOR } from "./assets/theme/base/colors";
import CustomButton from "./component/CustomButton";
import CertificateShowCase from "./component/CertificateShowCase";
const useStyles = makeStyles((theme) => ({
  
  header: {
    padding:'20px'
  },
  tableCell: {
    display:'flex',
    justifyContent: 'center',
    textAlign: 'center'
  },
  mainContainer: {

  },
  detailContainer: {
   
  },
  userHeader: {
    display: 'flex',
    flexDirection:'column',
    alignItems:'center',
    margin:'20px',
    padding:'30px',
    backgroundColor: PRIMARY_COLOR,
    borderRadius: '20px'
  },
  certificateContainer: {
    marginTop:'10px',
    marginBottom:'10px',
    paddingTop:'10px',
    paddingBottom:'10px'
  },
  certificateInfo: {
    padding:'0px 20px 0px 20px',
    marginBottom:'5px',
    backgroundColor:ACCENT_COLOR,
    color: SECONDARY_BUTTON_BACKGROUND_COLOR
    
  },
  userDetail: {
    width:'100%',
    marginTop:'20px',
    marginBottom:'10px'
  }
}));


const UserInfo = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const email = searchParams.get('userId');
  
  const isMobile = useMediaQuery('(max-width:700px)');
  const hash = Math.random().toString(36).substring(2, 8);
  const { currentUser, signOut ,getCourses} = useContext(AuthContext);
  const classes = useStyles();
  const [user, setUser] = useState({});
  const [certificates, setCertificates] = useState([]);
  const [isLoading, setLoading] = useState(false);
  console.log("HEY HERE");
  useEffect(() => {
    setLoading(true);
    console.log("HEY INSIDE USER INFO ")
    console.log(currentUser.isAdmin)
    if(currentUser.isAdmin || currentUser.email == email) {
      app.database().ref('users/'+email.replaceAll('.','_')).on('value', snapshot => {
        if(snapshot && snapshot.exists()) {
          console.log(JSON.stringify(snapshot));
          const certificateList = snapshot.val().certificates ? Object.values(snapshot.val().certificates) : [];
          const certificateUpdateList = [];
          if(certificateList) {
            certificateList.forEach(certificate => {
              console.log("CERTIFICATE : "+JSON.stringify(certificate))
              certificateUpdateList.push(certificate);
            })
          } 
          setCertificates(certificateUpdateList);
          setUser(snapshot.val());
        }
      }, error => {
        console.log(error);
      });
    }
    setLoading(false);
  }, [])
  
 
  const handleDownloadPdf = (url) => {
    window.open(url, '_blank');
  }

  
  const deleteCertificate = (certificateId) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this certificate?");
    if (confirmDelete) {
      const  certificateRef = app.database().ref(`/users/${email}/certificates/${certificateId}`);

      certificateRef.remove()
        .then(() => {
          console.log("Certificate deleted successfully");
          
        })
        .catch((error) => {
          console.error("Error deleting certificate: ", error);
        });
    }
  };
  

  return (
    <>
    <ByBackdrop  open={isLoading}/>
    <Box className={classes.mainContainer}>
     <Box className={classes.detailContainer}>
        <Box className={classes.userHeader}>
          <Avatar
                sx={{ 
                  width: '80px',
                  height: '80px',
                  backgroundColor: 'orange',
                  marginBottom: '10px',
                  border: '4px solid white',
                  borderColor: 'white'
                }}
                // src={`https://robohash.org/${user ? user.email : hash}?set=set4`} />
                 src={user.photoUrl} />
          <ModernText text={user.name} variant='HEADER' color='white'/>
        </Box>
        <Paper className={classes.userDetail} elevation={isMobile ? 0 : 2}>
          <div className={classes.header}>
           <ModernText text='Account' variant='HEADER'/>
          </div>
          <div>
          <Table>
            <TableRow>
              <TableCell><ModernText text='Email' variant='TITLE' /></TableCell>
              <TableCell  align='center'><ModernText text={user.email} variant='TITLE'  fontWeight='700' /></TableCell>
            </TableRow>
            <TableRow>
              <TableCell><ModernText text='Phone Number' variant='TITLE'/></TableCell>
              <TableCell align='center'><ModernText text={user.phone}  variant='TITLE'/></TableCell>
            </TableRow>
            {/* <TableRow>
              <TableCell><ModernText text='DOB' variant='TITLE'/></TableCell>
              <TableCell align='center'><ModernText text={user.dob}  variant='TITLE'/></TableCell>
            </TableRow> */}
            <TableRow>
              <TableCell><ModernText text='Address' variant='TITLE'/></TableCell>
              <TableCell  align='center'><ModernText  text={user.address}  variant='TITLE' /></TableCell>
            </TableRow>
          </Table>
          </div>
        </Paper>

        <Paper className={classes.certificateContainer} elevation={isMobile ? 0 : 2}>
          <div>
          
          <CertificateShowCase user={user}/>
{/* 
            {certificates.map((certificate) => {
          return (
          <div className={classes.certificateBox}>
            <div className={classes.certificateDetails}>
              <ModernText text={certificate.courseName} variant='secondary' />
              <ModernText text="12 Dec 2012" variant='secondary' />
            </div>
            <div>
              <CustomButton text='Download'/>
            </div>
            <hr/>
            </div>
      );
     })}
            */}
          </div>
        </Paper>
     </Box>
    </Box>
    </>
  );
};

export default UserInfo;
