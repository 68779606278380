import React, { useCallback, useContext, useState, useEffect } from "react";

import app from "./base.js";
import { AuthContext } from "./Auth";
import { makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import TextField from "@mui/material/TextField";
import Modal from 'react-bootstrap/Modal';
import ModernText from "./component/ModernText.js";
import ByBackdrop from "./component/ByBackdrop.js";
import { CheckCircleOutline } from '@mui/icons-material';
import CustomButton from "./component/CustomButton.js";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import { Box } from "@mui/material";
import CustomInputField from "./component/CustomInputField.js";
import { Auth } from "firebase-admin/lib/auth/auth.js";
const useStyles = makeStyles((theme) => ({
  inputBox: {
    lineHeight:0.2,
    marginBottom:20
  },
  label : {
    fontWeight: 500,
    color: "#333",
    fontSize: "12px"
  }
}));

const initialFormData = {
  email: '',
  password: '',
  confirmPassword: '',
  phone: '',
  name: '',
  address:''
};

const SaveUserDetail = ({ open, onClose , user}) => {
  
  const [formData, setFormData] = useState(initialFormData);
  
  const [admin, setAdmin] = useState(false);
const [isLoading, setLoading] = useState(false);
const [error, setError] = useState(null);

const handleClose = () => {
  onClose();
};

const openDialog = () => {
  handleClose();
};

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setError(null);
  };
  const [photoUrl, setPhotoUrl] = useState('');
  useEffect(() => {
    if(user && user.isAdmin) {
      setAdmin(true);
    } else if(user) {
      console.log("US EFFET"+JSON.stringify(user));
      console.log("BOOL " +admin)
      formData.email = user.email;
      formData.name = user.name ? user.name : user.displayName;
      formData.phone = user.phone ? user.phone : user.phoneNumber;
      formData.address = user.address;
      setPhotoUrl(user.photoUrl ? user.photoUrl : user.photoURL);
    }
    
  }, [user]);
  const saveUser = useCallback(
    async (event, isAdmin) => {
      event.preventDefault();
      try {
        setLoading(true);
        const userRef = app.database().ref(`/users/`+ formData.email.replaceAll('.', '_'));
        const snapshot = await userRef.once('value');
        if (snapshot.val() !== null) {
          // Update user if already exists and isAdmin is false
          if (!isAdmin) {
            const userData = snapshot.val();
            const updatedUser = {
              ...userData,
              name: formData.name.toUpperCase(),
              email: formData.email.toLowerCase(),
              address: formData.address,
              phone: formData.phone,
              photoUrl: photoUrl
            };
            await userRef.set(updatedUser);
            setFormData(initialFormData);
            setLoading(false);
            handleClose();
          } else {
            setLoading(false);
            setError('User already exists with this email id');
          }
        } else {
          const newUser = {
            name: formData.name.toUpperCase(),
            email: formData.email.toLowerCase(),
            createdAt: new Date().toISOString(),
            address: formData.address,
            phone: formData.phone,
            photoUrl: photoUrl,
            certificates: []
          };
          await userRef.set(newUser);
          setFormData(initialFormData);
          setLoading(false);
          handleClose();
        }
      } catch (error) {
        setLoading(false);
        setError(error);
      } 
    },
    [formData]
  );
  

  
  return (
    <>
    {/* <CustomButton type="secondary" variant="secondary" onClick={handleShow} text="Sign Up"/> */}
    
    <Dialog open={open} onClose={user && user.isAdmin ? onClose : null}>
    <DialogTitle>Save Detail</DialogTitle>
    <DialogContent style={{maxWidth:'400px'}}>
          
      <form onSubmit={saveUser}>
        
          <CustomInputField name="email" type="email" disabled={user ? !user.isAdmin : false}
          value={formData.email} required="true" placeholder="Email Address"
          onChange={handleChange}/>
          <CustomInputField name="phone" type="number"  maxLength={10}
          value={formData.phone} required="true" placeholder="Phone Number"
          onChange={handleChange}/>
          <CustomInputField name="name" type="text" 
          value={formData.name} required="true" placeholder="Full Name"
          onChange={handleChange}/>
          
          <CustomInputField name="address" type="text" 
          value={formData.address} required="true" placeholder="Address"
          onChange={handleChange}/>
         
        
          <ByBackdrop open={isLoading} />
          {error && (
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
              <p style={{ color: 'red', fontSize:"13px" }}>* {error}</p>
            </div>
          )}
         <Box style={{ display: 'flex', justifyContent: 'center', gap: '10px' }}>
  <CustomButton text="Submit"/>
   { user && user.isAdmin && <CustomButton text="Cancel" type="secondary"  variant = 'secondary' onClick={onClose}/> }
</Box>

                </form>
                </DialogContent>
  </Dialog>
            </>
  );
};
         


export default SaveUserDetail;

