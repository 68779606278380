import React, { useState, useContext } from 'react';
import { Modal } from 'react-bootstrap';
import {Button, Dialog, DialogContent, DialogTitle, TextField} from '@mui/material';
import ModernText from './component/ModernText';
import { AuthContext } from './Auth';
import AddIcon from '@mui/icons-material/Add';
import app from './base';
import { LinearProgress, IconButton, Select, MenuItem } from '@mui/material';
import ByBackdrop from './component/ByBackdrop';
import CloseIcon from '@mui/icons-material/Close'
import CustomInputField from './component/CustomInputField';
import CustomButton from './component/CustomButton';
const AddCertificateModal = ({ user}) => {
  const {currentUser, getCourses, courses } = useContext(AuthContext);
  
  const [courseId, setCourseId] = useState('');
  const [show, setShow] = useState(false);
  const [error, setError] = useState(null);
  const [issueDate, setIssueDate] = useState('');
  const [fromDate, setFromDate] = useState('');
  const [certificateId, setCertificateId] = useState('');
  const [toDate, setToDate] = useState('');
  const [file, setFile] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [progessVisible, setProgressVisible] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => {
    setUploadProgress(0);
    setProgressVisible(false);
    setShow(true)};

  const handleCourseChange = (event) => {
    setCourseId(event.target.value);
  };

  const handleFromDateChange = (event) => {
    setFromDate(event.target.value);
  };

  const handleIssueDateChange = (event) => {
    setIssueDate(event.target.value);
  };

  const handleToDateChange = (event) => {
    setToDate(event.target.value);
  };

  const handleCertificateIdChange = (event) => {
    setCertificateId(event.target.value);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setFile(file);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    
    try {
      setError(null);
      setLoading(true);
  
      // Check if the certificate ID already exists
      const certificateRef = app.database().ref(`/certificates/${certificateId}`);
      const snapshot = await certificateRef.once('value');
      if (snapshot.exists()) {
        setError('Certificate ID already exists');
        setLoading(false);
        return;
      }
  
      // Upload the certificate file to Firebase Storage
      const storageRef = app.storage().ref(`/certificates/${user.email.replaceAll('.','_')}`);
      const fileRef = storageRef.child(`${certificateId}.pdf`);
      const uploadTask = fileRef.put(file);
      setProgressVisible(true);
      uploadTask.on('state_changed', (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setUploadProgress(progress);
      });
      const snapshotFile = await uploadTask;
      const downloadURL = await snapshotFile.ref.getDownloadURL();
  
      // Add the certificate to the database
      const newCertificate = {
        certificateId,
        ownerEmailId: user.email,
        ownerName: user.name,
        courseName: courses[courseId] ? courses[courseId].courseName : 'UNKNOWN',
        fromDate,
        toDate,
        issueDate,
        uploadDate: new Date().toISOString(),
        downloadURL,
      };
      await certificateRef.set(newCertificate);
  
      // Add the certificate ID to the user's list of certificates
      const userCertificatesRef = app.database().ref(`/userCertificates/${user.email.replaceAll('.','_')}/${certificateId}`);
      
      await userCertificatesRef.set(certificateId);
      
      // Reset the form fields and show a success message
      setCourseId('');
      setFromDate('');
      setToDate('');
      setIssueDate('');
      setFile(null);
      setLoading(false);
      alert('Certificate added successfully!');
      handleClose();
    } catch (error) {
      setLoading(false);
      setError(error.message);
    }
  };
  

  return (
    <>
    { currentUser.isAdmin &&
    <div>
      <ByBackdrop open= {isLoading}/>
      <CustomButton variant="primary" onClick={() => handleShow()} text="Add Certificate"/>
      {/* <Button  variant="contained"  color="success"  startIcon={<AddIcon />} onClick={() => handleShow()}>
      <ModernText text="Add Certificate" fontSize="13px" />
          </Button> */}
      <Dialog open={show} onClose={handleClose}>
        <DialogTitle sx={{display:'flex', justifyContent:'space-between'}}>Add Certificate<IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton></DialogTitle>
        <DialogContent style={{maxWidth:'400px'}}>
        <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <ModernText text="Certificate ID" fontSize="12px"/>  
              <TextField type="text" name="certificateId" 
              fullWidth
              required
              size="small"
              value={certificateId} onChange={handleCertificateIdChange}/>
            </div>
            <div className="mb-3">
              <ModernText text="Course:" fontSize="12px"/>
              <Select className="form-select" id="course" name="course" 
              style={{padding:0}}
              size="small"
              value={courseId} onChange={handleCourseChange} required>
              <MenuItem value="">
                <em>Select Course</em>
              </MenuItem>
              {getCourses().length > 0 && getCourses().map((course)=>(
                  <MenuItem value={course.key}>{course.courseName}</MenuItem>
                  
                ))}
              </Select>
            </div>

            <div className="mb-3">
            <ModernText text="Issue Date:" fontSize="12px"/>  
            <TextField type="date" name="issueDate" 
            fullWidth
            size="small"
            required
            value={issueDate} onChange={handleIssueDateChange}/>
            </div>


            <div className="mb-3">
            <ModernText text="From Date:" fontSize="12px"/>  
            <TextField type="date" name="fromDate" 
            fullWidth
            size="small"
            value={fromDate} onChange={handleFromDateChange}/>
              
            </div>
            <div className="mb-3">
            <ModernText text="To Date:" fontSize="12px"/>
            <TextField type="date" name="toDate" 
             size="small"
            fullWidth
            value={toDate} onChange={handleToDateChange}/>
              
            </div>
            <div className="mb-3">
            <ModernText text="Upload File:" fontSize="12px"/>
              <TextField
                id="file"
                name="file"
                type="file"
                onChange={handleFileChange}
                required
                fullWidth
                margin="normal"
                size="small"
              />
              {/* <input type="file" id="file" name="file" onChange={handleFileChange} required /> */}
        </div>
        <CustomButton text="Upload" variant='primary'/>
        {error && <ModernText text={error} color='red'/>}
      </form>
      <div style={{
        marginTop:10
      }}>
      {progessVisible && <LinearProgress variant="determinate" value={uploadProgress} /> }
      </div>
        </DialogContent>
      </Dialog>
      </div>} </>
    );
  }

  export default AddCertificateModal;
  