import React from "react";
import Typography from '@mui/material/Typography';
import { useMediaQuery } from '@mui/material';
import { PRIMARY_TEXT_COLOR, SECONDARY_TEXT_COLOR, DANGER } from "../assets/theme/base/colors";

const ModernText = ({text, variant, fontSize, fontWeight, color, userSelect}) => {

    let textColor = PRIMARY_TEXT_COLOR;
    let textFontWeight = 200;
    let textFontSize = "15px";
    let textLineHeight = "10px";
    let userSelectText = 'text';
    const isMobile = useMediaQuery('(max-width:700px)');
    if (variant === "TITLE") {
        textColor = PRIMARY_TEXT_COLOR;
        textFontWeight = 200;
        textFontSize = isMobile ? '13px': "15px";
        textLineHeight = isMobile ? '13px': "15px";
        
    } else if (variant === "SECONDARY") {
        textColor = SECONDARY_TEXT_COLOR;
        textFontWeight = 200;
        textFontSize = isMobile ? '10px': "13px";
        textLineHeight = isMobile ? '14px': "16px";
    } else if (variant === "HEADER") {
        textColor = PRIMARY_TEXT_COLOR;
        textFontWeight = 300;
        textFontSize = isMobile ? '18px': "25px";
        textLineHeight = isMobile ? '18px': "25px";;
        userSelectText = 'none';
    } else if( variant == 'ERROR') {
        textColor = DANGER;
        textFontWeight = 200;
        textFontSize = isMobile ? '12px': "15px";
        textLineHeight = isMobile ? '12px': "15px";
        userSelectText = 'none';
    }

    if(color) {
        textColor = color;
    }

    if(fontSize) {
        textFontSize = fontSize;
    }

    if(fontWeight) {
        textFontWeight = fontWeight;
    }

    if(userSelect) {
        userSelectText = userSelect;
    }

    return (
        <Typography variant="h6" component="h3" 
         style={{
            userSelect: userSelectText,
            fontSize: textFontSize,
            fontFamily: "'Ubuntu', sans-serif", // Updated fontFamily
            fontWeight: textFontWeight,
            letterSpacing: "0.05em",
            lineHeight: textLineHeight,
            color: textColor
            }}
            >
          <b>{text}</b>
        </Typography>
    )
};

export default ModernText;
