import React, { useState, useContext } from 'react';
import {
  Box,
  Card,
  Collapse,
  Grid,
  IconButton,
  Typography,
  Avatar,
  Paper
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
  formatDate
} from '../Util';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { useHistory } from 'react-router-dom';
import '../css/UserCardMobile.css';
import ModernText from './ModernText';

const useStyles = makeStyles((theme) => ({
  cardContainer: {
   
    padding: '10px',
    display: 'flex',
    flexDirection:'column',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: '#f5f5f5',
    },
  },
  avatar: {
    width: '60px',
    height: 'auto',
    borderRadius: '50%',
    
    marginRight:'30px'
  },
  userInfo: {
    margin:'2px',
    padding:'10px',
    display: 'flex',
    flexDirection: 'row',
    alignItems:'center'
  },
}));

const initialFormData = {
  courseName: '',
  duration: '',
  description: ''
};

const UserCardMobile = ({users}) => {
  const classes = useStyles();
  const history = useHistory();
  const hash = Math.random().toString(36).substring(2, 8);
  const [openCollapse, setOpenCollapse] = useState(false);

  const handleCollapse = () => {
    setOpenCollapse(!openCollapse);
  };

  const showUserInfo = (user) => {
    history.push(`/userInfo?userId=${user.key}`);
  }

  return (
    <Grid item xs={12} sm={12} md={12} lg={12}>
      <Box className={classes.cardContainer} elevation={0} >
        {users.map((user) =>(
         <>
         <Paper className={classes.userInfo} onClick={() => showUserInfo(user)}>
         <img
           className={classes.avatar}
           // src={`https://robohash.org/${user ? user.email : hash}?set=set4`}
           src={user.photoUrl}
         />
         <Box>
           <ModernText text={user.name} variant='TITLE'/>
           <ModernText text={user.email} variant='SECONDARY'/>
           {/* <ModernText text={formatDate(user.dob)} variant='SECONDARY'/> */}
           <ModernText text={user.phone} variant='SECONDARY'/>
         </Box>
       </Paper>
       </> 
        ))}
       
      </Box>
    </Grid>
  );
};

export default UserCardMobile;
