import React, { useState, useContext, useEffect } from 'react';
import ShowUserListMobile from './component/ShowUserListMobile';
import ShowUserListWeb from './ShowUserListWeb';
import { useMediaQuery, Hidden } from '@mui/material';

const ShowUserList = ({ users }) => {
  // const isMobile = useMediaQuery('(max-width:300px)');

  return (
    <>
     <Hidden mdUp>
        <ShowUserListMobile users={users} />
      </Hidden>
      <Hidden mdDown>
        <ShowUserListWeb users={users} />
      </Hidden>
    </>
  );
};

export default ShowUserList;