import React from "react";
import { ACCENT_COLOR, DARK_PRIMARY_COLOR, LIGHT_PRIMARY_COLOR, PRIMARY_BUTTON_BACKGROUND_COLOR, PRIMARY_BUTTON_TEXT_COLOR, PRIMARY_COLOR, SECONDARY_BUTTON_BACKGROUND_COLOR, SECONDARY_BUTTON_TEXT_COLOR } from "../assets/theme/base/colors";
import SearchIcon from '@mui/icons-material/Search';
import { Button } from "@mui/material";
import { useMediaQuery } from '@mui/material';
import ModernText from "./ModernText";

const CustomButton = ({ type= 'submit' , text = '', onClick, variant = 'primary', startIcon = null, endIcon = null }) => {
  const buttonVariant = variant === 'primary' ? 'contained' : 'outlined';
  const buttonColor = variant === 'primary' ? 'primary' : 'secondary';
  const backgroundColor = variant === 'primary' ? PRIMARY_COLOR : '#fff';
  const textColor = variant === 'primary' ? PRIMARY_BUTTON_TEXT_COLOR : SECONDARY_BUTTON_TEXT_COLOR;

  const isMobile = useMediaQuery('(max-width:700px)');
  
  return (
    <Button
      type = {type}
      variant={buttonVariant}
      color={buttonColor}
      style={{ 
        backgroundColor: backgroundColor, 
        color: textColor ,
        marginTop:10,
        marginBottom:3,
        width:'100%',
        borderRadius:'25px',
        textTransform: 'none',
        padding:isMobile ? '13px' :'15px'
      }}
      startIcon={startIcon}
      endIcon={endIcon}
      onClick={onClick}
    >
         <ModernText text={text} fontSize={isMobile ? '13px' :'17px'} color={textColor} userSelect='none' />
    </Button>
  );
};

export default CustomButton;
